.nomadModal {
    background-color: #018bc2 !important;
    max-width: 60% !important;
}

.nomadHeader {
    background-color: #018bc2 !important;
    color: white !important;
    border: none !important;
}

.nomadBody {
    padding-top:40px;
    background-color: #018bc2 !important;
}

.nomadHeadline {
    padding-top:40px;
    font-size:3vw !important;
    font-weight: 900 !important;
    color: #0f215b;
}

.nomadLink {
    color: white;
    padding-bottom:40px;
    cursor:pointer;
}

@media only screen 
and (max-device-width : 375px) 
and (orientation : portrait) { 
    .nomadModal {
        max-width: 95% !important;
    }

    .nomadLink {
        font-size:5vw !important;
    }

    .nomadHeadline {
        font-size:8vw !important;
    }
}

@media only screen 
and (max-device-width : 414px) { 
    /* STYLES GO HERE */
    .nomadModal {
        max-width: 98% !important;
    }

    .nomadLink {
        font-size:5vw !important;
    }

    .nomadHeadline {
        font-size:8vw !important;
    }
}

@media only screen 
and (max-device-width : 360px) { 
    /* STYLES GO HERE */
    .nomadModal {
        max-width: 93% !important;
    }

    .nomadLink {
        font-size:5vw !important;
    }

    .nomadHeadline {
        padding-top:20px;
        font-size:8vw !important;
    }
}