.container {
    position: relative;
    width: 100%;
}

.container:hover .image {
    opacity: 1;
}

.container:hover .middle {
    opacity: 1;
}
  
.image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width:100%;
}

.text {
    background-color:transparent;
    color: white;
    font-size: 18px;
    padding: 50px 5px;
    font-weight: 800;
    text-align: left;
}

.header {
    padding-top:10px;
    background-color:rgba( 0, 0, 0, 0.7);
    color: white;
    font-weight: 600;
    height:75px;
    width:100%;
}